$(function () {

    // manipulacija url parametrima, miče # iz urla i updatea parametre, ako parametar ne postoji stvori ga
    function updateQueryStringParameter(uri, key, value) {

        if (uri.indexOf('#')) { uri = uri.split('#')[0]; }

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    }

    function checkHash() {
        var hash = window.location.hash;
        if (hash) {

            if (!$(hash).length) { return; }

            $('html,body').animate({

                scrollTop: $(hash).offset().top

            }, 300, 'swing');
        }
    }

    checkHash();

    // fake select
    function initSelect() {

        $('.js_selectWrap').each(function () {

            var el = $(this),
                $btn = el.find('.fake__form_select_btn'),
                $list = el.find('.fake__form_select_list'),
                $item = el.find('.fake__form_select_item'),
                select = el.find('.fake__form_select_box'),
                selected = $('.js_selectedSection');

            el.addClass('js_selectWrapFN');

            $btn.on('click', function () {
                $list.addClass('is_visible');
            });

            $list.on('mouseleave', function () {
                $list.removeClass('is_visible');
            });

            $item.on('click', function (e) {

                var _item_text = $(this).html(),
                    _item_value = $(this).data('value'),
                    option = select.find('option[value="' + _item_value + '"]');

                $list.removeClass('is_visible');
                $btn.html(_item_text);
                select.val(_item_value);

                document.location.href = updateQueryStringParameter(document.location.href, 'order', _item_value);

            });

            if (selected.html()) {

                var option = select.find('option[value="' + selected.html() + '"]');
                $btn.html(option.html());
            }
        });
    }

    // commentLocation
    var currentLocation = window.location.pathname,
        commentLocation = 'Stranica komentara';

    if (currentLocation.indexOf('/komentari') === -1) {
        commentLocation = 'Članak';
    }

    // plugin za autoresize textarea - radi na svima, staviti atribut data-autoresize
    function initTextareaAutoResize() {

        $.each($('textarea[data-autoresize]'), function () {

            var $textarea = $(this);

            // maxlength parametar na textarea
            var limit = $(this).attr('maxlength');

            var $counter = $textarea
                .closest('.onecomment')
                .find('.js_textareaCount > span');

            var offset = this.offsetHeight - this.clientHeight;

            // autoresize bez fake elementa
            function resizeTextarea(el) {

                var $el = $(el);

                $el.css('height', 'auto')
                    .css('height', el.scrollHeight + offset);
            }

            function countChars(el) {
                $counter.parent('.js_textareaCount').addClass('is_visible');
                $counter.text(
                    (limit - $(el).val().length)
                );
            }

            $textarea.on('keyup input', function (e) {

                resizeTextarea(this);
                countChars(this);

                if (e.ctrlKey && e.keyCode == 13) {
                    $textarea
                        .closest('.onecomment__form')
                        .find('.js_sendNewComment, .js_sendReplyComment')
                        .trigger('click');
                }
            });

            $textarea
                .removeAttr('data-autoresize')
                .addClass('js_initTextareaAutoResize');
        });
    }

    function toggleSubcomment($toggleBtn, e) {
        e.preventDefault();

        // otvaranje commentboxa zajedno sa odgovorima
        if (!$toggleBtn.siblings('.js_showReplyComment').hasClass('has_commentWindow')) {

            $toggleBtn.siblings('.js_showReplyComment').trigger('click');
        }

        const $subComment = $toggleBtn.closest('.thread__item').find('.thread__sublist');

        $toggleBtn.toggleClass('is_active');

        if ($toggleBtn.hasClass('is_active')) {

            $subComment.addClass('is_visible');

        } else {

            $subComment.removeClass('is_visible');

            // zatvaranje commentboxa
            $toggleBtn.closest('.thread__item').find('.js_cancelComment').trigger('click');
        }

        dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': 'Komentari',
            'eventAction': 'Prikaži odgovore',
            'eventLabel': commentLocation,
            'eventValue': undefined
        });
    }

    function showMoreComment($toggleBtn, e) {
        e.preventDefault();

        var $hiddenContent = $toggleBtn
            .closest('.onecomment__content, .onereply__content, .commbox__content')
            .find('.js_onecommentContentHidden');

        $hiddenContent
            .removeClass('onecomment__content--hidden onereply__content--hidden commbox__post_content--hidden ');

        $toggleBtn.off().hide()
            .siblings('.js_onecommentVisible').find('.commbox__hellip').hide();

        dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': 'Komentari',
            'eventAction': 'Prikaži još - tekst link',
            'eventLabel': commentLocation,
            'eventValue': undefined
        });
    }

    function initReplyComments() {

        var $showReplyComments = $('.js_showReplyComment');

        $showReplyComments.each(function () {

            var $showReplyComment = $(this);

            $showReplyComment.on('click', function (e) {

                showReplyComments($(this), e);
            });
        });
    }

    function voteForComment($toggleBtn, e) {
        e.preventDefault();

        if ($toggleBtn.hasClass('btn--invert')) { return; }

        var $ratingBox = $toggleBtn.closest('.onecomment__rating, .commbox__rating');

        $.ajax({

            type: "POST",
            url: $toggleBtn.data('api'),

            success: function (data) {

                $ratingBox
                    .find('.js_like, .js_dislike')
                    .removeClass('btn--invert');

                $toggleBtn.addClass('btn--invert');

                $ratingBox.find('.js_like .vote__value').text(data.plus);
                $ratingBox.find('.js_dislike .vote__value').text(data.minus);
                $ratingBox.find('.js_line').css({ 'width': data.percentage + '%' });

                dataLayer.push({
                    'event': 'GAEvent',
                    'eventCategory': 'Komentari',
                    'eventAction': 'Dobar / Loš',
                    'eventLabel': commentLocation,
                    'eventValue': undefined
                });
            },

            statusCode: {

                403: function () {
                    console.warn('Korisnik nije logiran');
                },

                408: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                500: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                502: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    }

    function profileFollowActions($toggleBtn, e) {
        e.preventDefault();
        $.ajax({

            type: "POST",
            url: $toggleBtn.data('api'),

            success: function (data) {

                if ($toggleBtn.hasClass('js_profileUnfollow')) {

                    $toggleBtn
                        .removeClass('is_visible')
                        .siblings('.js_profileFollow')
                        .addClass('is_visible');

                } else {

                    $toggleBtn
                        .removeClass('is_visible')
                        .siblings('.js_profileUnfollow')
                        .addClass('is_visible');
                }
            },

            statusCode: {

                403: function () {
                    console.warn('Korisnik nije logiran');
                },

                408: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                500: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                502: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    }

    function flagComment($toggleBtn, e) {
        e.preventDefault();

        if ($toggleBtn.hasClass('btn--invert')) { return; }

        $.ajax({

            type: "POST",
            url: $toggleBtn.data('api'),

            success: function (data) {

                $toggleBtn.addClass('btn--invert');

                dataLayer.push({
                    'event': 'GAEvent',
                    'eventCategory': 'Komentari',
                    'eventAction': 'Flagiranje',
                    'eventLabel': commentLocation,
                    'eventValue': undefined
                });
            },

            statusCode: {

                403: function () {
                    console.warn('Korisnik nije logiran');
                },

                408: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                500: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                502: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    }

    function deleteComment($toggleBtn, e) {
        e.preventDefault();

        if ($toggleBtn.hasClass('btn--invert')) { return; }

        $.ajax({

            type: "POST",
            url: $toggleBtn.data('api'),

            success: function (data) {

                $toggleBtn.addClass('btn--invert');

                $toggleBtn
                    .closest('.thread__item')
                    .addClass('thread__item--deleted')
                    .fadeIn() // ubačena animacija koja ne radi ništa jer delay radi samo nakone neke animacija
                    .delay(1500)
                    .slideUp(function () {
                        $(this).remove();
                    });

                dataLayer.push({
                    'event': 'GAEvent',
                    'eventCategory': 'Komentari',
                    'eventAction': 'Flagiranje',
                    'eventLabel': commentLocation,
                    'eventValue': undefined
                });
            },

            statusCode: {

                403: function () {
                    console.warn('Korisnik nije logiran');
                },

                408: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                500: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },

                502: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    }

    function newComment($toggleBtn, e) {
        e.preventDefault();

        var threadID = $toggleBtn.data('thread-id'),
            postID = $toggleBtn.data('post-id'),
            commentObject = $toggleBtn.data('comment-object'),
            isRecaptchaEnabled = $toggleBtn.data('is-recaptcha-enabled');

        // tekst poruke koju šaljemo
        var $commentText = $toggleBtn
            .closest('.onecomment__form')
            .find('.js_commentText');

        var headPostID = $toggleBtn
            .closest('.js_oneCommentReply')
            .siblings('.js_oneComment')
            .attr('id');

        var ajaxPath = $('#' + headPostID).data('thread-url');
        var newPath = '';

        if ($commentText.val().length === 0) { return; }

        $toggleBtn
            .removeClass('btn--r')
            .addClass('btn--disabled')
            .css('pointer-events', 'none');

        var commentObj = {
            'content': $commentText.val(),
            'comment_object': commentObject
        };

        if (isRecaptchaEnabled) {
            var captchaContainer = $toggleBtn.closest('form').find(".captcha-container").first();
            var recaptcha_response = $('#g-recaptcha-response').val();

            if (!recaptcha_response) {
                var container = captchaContainer.find('.g-recaptcha-response').first();
                var container_id = container.attr('id');

                recaptcha_response = $(`#${container_id}`).val();
            }

            if (!recaptcha_response) {
                captchaContainer.addClass('shake');
                setTimeout(function () {
                    captchaContainer.removeClass('shake');
                }, 1000);
            }
            commentObj.g_recaptcha_response = recaptcha_response;
        }

        if (threadID) {
            commentObj.thread_id = threadID;
        }

        if (postID) {
            commentObj.post_id = postID;
        }

        $.ajax({

            type: "POST",
            url: $toggleBtn.data('api'),
            data: commentObj,

            success: function (data) {

                $commentText.val('');

                var txtMessage = data.message,
                    newPath = data.url;

                if (ajaxPath) { // imaju samo odgovori na komentar pa je ovo odgovor

                    if (!$('#' + headPostID).length) { return; }

                    $('#' + headPostID)
                        .closest('.thread__item')
                        .load(ajaxPath, function () {

                            // eventi za nove ajax komentare
                            initComments();
                            $('#' + headPostID)
                                .closest('.thread__item')
                                .find('.js_showReplyComment ')
                                .trigger('click');

                            if ($('body').hasClass('post_view')) {
                                $('.post_view .js_oneCommentReply').show();
                            }

                        });

                } else { // a ovo je novi komentar

                    setTimeout(function () {

                        if (newPath) {
                            window.location.href = newPath;
                        } else {
                            location.reload();
                        }

                        window.onhashchange = function () {
                            location.reload();
                        };

                    }, 600);
                }
            },

            error: function (jqXHR, textStatus, errorThrown) {
                $toggleBtn
                    .removeClass('btn--disabled')
                    .addClass('btn--r')
                    .css('pointer-events', 'auto');

                var $toaster = $('.js_toaster');
                $toaster.addClass('is_open');
                $toaster.find('.js_toasterText').text(jqXHR.responseJSON.message);

                setTimeout(function () {
                    $toaster.removeClass('is_open');
                }, 3000);

                $('.js_closeToaster').on('click', function () {
                    $toaster.removeClass('is_open');
                });
            },

            statusCode: {

                403: function () {
                    console.warn('Korisnik nije logiran');
                },
                408: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },
                429: function () {
                },
                500: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                },
                502: function () {
                    console.error('Problem sa serverom - Pokušajte kasnije');
                }
            }
        });
    }

    function showReplyComments($toggleBtn, e) {
        e.preventDefault();

        if ($toggleBtn.hasClass('has_commentWindow')) {

            $('html,body').animate({

                scrollTop: ($toggleBtn.closest('.thread__item').find('.js_oneCommentReply').offset().top - $(window).height() / 2)

            }, 300, 'swing');

            return;
        }

        $('.js_cancelComment').trigger('click'); // očisti prozor za komentiranje

        var threadID = $toggleBtn.data('thread-id'),
            postID = $toggleBtn.data('post-id'),
            apiURL = $toggleBtn.data('comment-object');

        var $commentWindow = $('.js_newComment').clone(); // markup za novi kommentbox

        // kloniranje i modificiranje donjeg kommentboxa
        $commentWindow
            .removeClass('js_newComment')
            .addClass('js_oneCommentReply')
            .css({ 'display': 'none' });

        // modificiranje gumba za odgovor na komentar
        $commentWindow
            .find('.js_sendNewComment')
            .removeClass('js_sendNewComment')
            .addClass('js_sendReplyComment')
            .attr({
                'data-thread-id': threadID,
                'data-post-id': postID,
                'data-api': apiURL,
            })
            .text('Odgovori');

        // dodavanje autoresize atributa na textarea
        $commentWindow
            .find('.js_commentText')
            .attr({
                'data-autoresize': '',
                'style': ''
            });

        // ukoliko kopirana forma ima vidljiv counter sakrijemo ga
        $commentWindow
            .find('.js_textareaCount')
            .removeClass('is_visible');

        $commentWindow.find('form').attr({"id": `post-form-${postID}`});
        $commentWindow.find('form .captcha-container').attr(
            {"id": `recaptcha-post-${postID}`}).empty();

        $toggleBtn
            .addClass('has_commentWindow')
            .closest('.thread__item')
            .append($commentWindow);

        $commentWindow.slideDown(function () {

            initTextareaAutoResize();
        });

        // dodavanje evenata
        var $replyComments = $commentWindow.find('.js_sendReplyComment');
        var $cancelComments = $commentWindow.find('.js_cancelComment');

        $replyComments.on('click', function (e) {

            newComment($(this), e);
        });

        if (typeof window.grecaptcha !== 'undefined') {
            window.grecaptcha.render(
                `recaptcha-post-${postID}`, {
                    'sitekey': GR_SITE_KEY
                }
            );
        }

        $cancelComments.on('click', function (e) {
            e.preventDefault();

            $toggleBtn.removeClass('has_commentWindow');
            $(this)
                .closest('.js_oneCommentReply')
                .slideUp(function () {

                    $(this).remove();
                });
        });

    }

    function bindEvents($oneComment) {

        // events for one comment

        if ($oneComment.hasClass('js_bindEvents')) { return; }
        $oneComment.addClass('js_bindEvents');

        var $likeComment = $oneComment.find('.js_like'),
            $dislikeComment = $oneComment.find('.js_dislike'),

            $profileFollow = $oneComment.find('.js_profileFollow'),
            $profileUnfollow = $oneComment.find('.js_profileUnfollow'),

            $flagComment = $oneComment.find('.js_flagComment'),
            $deleteComment = $oneComment.find('.js_deleteComment'),
            $showMoreComment = $oneComment.find('.js_showMoreComment'),
            $toggleSubcomment = $oneComment.find('.js_toggleSubcomment');

        $likeComment.on('click', function (e) { voteForComment($(this), e); });
        $dislikeComment.on('click', function (e) { voteForComment($(this), e); });

        $profileFollow.on('click', function (e) { profileFollowActions($(this), e); });
        $profileUnfollow.on('click', function (e) { profileFollowActions($(this), e); });

        $flagComment.on('click', function (e) { flagComment($(this), e); });
        $deleteComment.on('click', function (e) { deleteComment($(this), e); });
        $showMoreComment.on('click', function (e) { showMoreComment($(this), e); });
        $toggleSubcomment.on('click', function (e) { toggleSubcomment($(this), e); });
    }

    function initComments() {

        initReplyComments();

        var $allComments = $('.js_oneComment'),
            $newComment = $('.js_sendNewComment'),
            $cancelComment = $('.js_cancelComment'),
            $toggleAllSubcomment = $('.js_toggleAllSubcomment');

        if (!$newComment.hasClass('js_sendNewCommentInit')) {
            $newComment.addClass('js_sendNewCommentInit');
            $newComment.on('click', function (e) { newComment($(this), e); });
        }

        $cancelComment.on('click', function (e) {
            e.preventDefault();

            $(this)
                .closest('.onecomment__content')
                .find('.js_commentText')
                .val('');
        });

        $toggleAllSubcomment.on('click', function (e) {
            e.preventDefault();

            $('.js_toggleSubcomment').trigger('click');

            dataLayer.push({
                'event': 'GAEvent',
                'eventCategory': 'Komentari',
                'eventAction': 'Prikaži sve odgovore',
                'eventLabel': commentLocation,
                'eventValue': undefined
            });
        });

        $allComments.each(function () {

            var $oneComment = $(this);
            bindEvents($oneComment);

        });
    }

    initComments();
    initSelect();
    initTextareaAutoResize();
});
